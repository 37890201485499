<template>
  <head-panel>
    <template v-slot:body>
      Баннер <span v-if="banner"> {{ banner.id }}</span></template>
  </head-panel>

  <div class="wrapper edit">

    <Alert ref="alert"/>

    <form @submit.prevent="submit" id="upload">

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Назва</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">ALT</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.alt" :class="{ 'is-invalid' : v$.form.alt.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.alt.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Url</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.url" :class="{ 'is-invalid' : v$.form.url.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.url.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Позиція</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.weight"
                 :class="{ 'is-invalid' : v$.form.weight.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.weight.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Статус</div>
        <div class="col-lg-8">

          <select class="form-select mapping-config" v-model="form.status">
            <option :value="status.value" v-for="status in statuses" :key="status">{{ status.label }}</option>
          </select>

          <div class="invalid-feedback" v-for="error of v$.form.status.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Теги</div>
        <div class="col-lg-8">
          <multiselect v-model="form.tags" tag-placeholder="Додати тег" placeholder="Додати тег" label="code"
                       track-by="code" :options="[]" :multiple="true" :taggable="true" @tag="addTag"
                       :class="{ 'is-invalid' : v$.form.tags.$error}"
          >
            <template #noOptions>
              Теги використовуються для пошуку банера(ів). Доволено використовувати символи для імені a-z, 0-9
            </template>
          </multiselect>
          <div class="invalid-feedback" v-for="error of v$.form.tags.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4" v-for="(item, index) in resources" :key="item">

        <hr/>

        <div class="col-lg-3">

          <div class="ms-2 move">
            <svg @click="up(index)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-caret-up-fill" viewBox="0 0 16 16">
              <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
            </svg>

            <svg @click="down(index)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
          </div>

        </div>
        <div class="col-lg-7 ">



          <div class="mb-2">
            <label>Type</label>
            <input type="text" class="form-control" v-model="item.type"
                   :class="{ 'is-invalid' : v$.resources.$each.$response.$errors[index].type.length}">
            <div class="invalid-feedback" v-for="error of v$.resources.$each.$response.$errors[index].type"
                 :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>

          <div class="mb-2">
            <label>Max width</label>
            <input type="text" class="form-control" v-model="item.maxWidth"
                   :class="{ 'is-invalid' : v$.resources.$each.$response.$errors[index].maxWidth.length}">
            <div class="invalid-feedback" v-for="error of v$.resources.$each.$response.$errors[index].maxWidth"
                 :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>

          <div class="file-input " v-if="!item.media">
            <input class="form-control file" type="file" accept="image/png, image/jpeg" :id="'file_'+index"
                   :name="'file_'+index" ref="preview" v-on:change="e => uploadPreview(e, index)">

            <label :for="'file_'+index">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-cloud-upload" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                <path fill-rule="evenodd"
                      d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"/>
              </svg>
              Select file: jpg, png
            </label>

          </div>


          <span class="preview" v-if="item.media">
              <img v-bind:src="$filters.image(item.media, '180x180')" width="70" height="70" class="img-thumbnail">
              <svg @click="remove(index)" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                   fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                      <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
              </svg>
          </span>

        </div>

      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";
import Multiselect from "vue-multiselect";
import {useVuelidate} from "@vuelidate/core";
import {helpers, integer, maxLength, required} from "@vuelidate/validators";

export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, Alert, Multiselect
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      resources: {
        $each: helpers.forEach({
          type: {
            maxLength: maxLength(50),
          },
          maxWidth: {
            integer,
          },
          media: {}
        })
      },
      form: {
        name: {
          required,
          maxLength: maxLength(255)
        },
        alt: {
          required,
          maxLength: maxLength(255)
        },
        url: {
          maxLength: maxLength(255),
          pattern: helpers.withMessage('Invalid url format', (v) => {
            if (!v)
              return true;

            return !!v.match("^[a-z0-9\\-.:_/]+$")
          })
        },
        status: {
          required
        },
        weight: {
          integer
        },
        tags: {
          $each: helpers.forEach({
            code: {
              maxLength: maxLength(50),
              pattern: helpers.withMessage('Invalid tag format', (v) => v.match("^[a-z0-9\\-]+$"))
            }
          })
        }
      }
    };
  },
  computed: {
    baseUrl: function () {
      return process.env.VUE_APP_BANNER_BASE_URL;
    },
    statuses() {
      return [
        {value: 'ACTIVE', label: 'ACTIVE'},
        {value: 'DISABLED', label: 'DISABLED'},
      ];
    },
  },
  data() {
    return {
      serviceName: process.env.VUE_APP_ADS_SERVICE,
      banner: null,
      resources: [],
      files: [],
      form: {
        name: null,
        alt: null,
        url: null,
        status: null,
        weight: null,
        tags: []
      }
    }
  },
  methods: {
    fetch: function (uuid) {
      Http
          .get(process.env.VUE_APP_ADS_SERVICE + "/manage/banner/" + uuid)
          .then((res) => {
            this.banner = res.content;
            this.form.name = this.banner.name;
            this.form.alt = this.banner.alt;
            this.form.url = this.banner.url;
            this.form.status = this.banner.status;
            this.form.weight = this.banner.weight;
            this.form.tags = this.banner.tags ? this.banner.tags.map(el => {
              return {code: el};
            }) : [];


            const initCountItems = 5;
            const countItems = this.banner.data ? this.banner.data.length : 0;
            const dataItems = this.banner.data ? this.banner.data : [];

            this.resources = [];
            let weight = 0;

            for (let el of dataItems) {
              this.resources.push({
                weight: weight++,
                type: el.type,
                maxWidth: el.maxWidth,
                media: el.media
              });
            }

            for (let i = 0; i < initCountItems - countItems; i++) {
              this.resources.push({
                weight: weight++,
                type: null,
                maxWidth: null,
                media: null
              });
            }

            this.resources.sort((a, b) => a.path && b.path ? a.weight - b.weight : 0);
          });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag
      }
      this.form.tags.push(tag)
    },
    uploadPreview: function (e, index) {
      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);

      Http.post(process.env.VUE_APP_PIO_SERVICE + "/v1/image/upload/" + process.env.VUE_APP_PIO_BUCKET_BANNER + "/multipart", fd)
          .then(res => {
            this.resources[index].media = res.data.path;
          });
    },
    remove: function (index) {
      this.resources[index].media = null;
    },
    up: function (index) {

      if (index === 0)
        return;

      if (!this.resources[index].path)
        return;

      this.resources[index - 1].weight += 1;
      this.resources[index].weight -= 1;
      this.resources.sort((a, b) => a.path && b.path ? a.weight - b.weight : 0);
    },
    down: function (index) {

      if (index === this.resources.length)
        return;

      if (!this.resources[index + 1].media)
        return;

      this.resources[index + 1].weight -= 1;
      this.resources[index].weight += 1;
      this.resources.sort((a, b) => a.media && b.media ? a.weight - b.weight : 0);
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        name: this.form.name,
        alt: this.form.alt,
        url: this.form.url,
        weight: this.form.weight,
        status: this.form.status,
        tags: this.form.tags.map(t => t.code),
        data: this.resources.filter(e => e.media)
      };

      if (!this.banner)
        Http
            .post(process.env.VUE_APP_ADS_SERVICE + "/manage/banner", data)
            .then((res) => {
              this.$router.replace("/banner/" + res.content);
              this.fetch(res.content);
              this.$refs.alert.success('Saved');
            });
      else
        Http
            .put(process.env.VUE_APP_ADS_SERVICE + "/manage/banner/" + this.$route.params.id, data)
            .then(() => {
              this.fetch(this.$route.params.id);
              this.$refs.alert.success('Saved');
            });
    }
  }
};
</script>

<style scoped>

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  position: relative;
  width: 200px;
  height: 30px;
  border-radius: 6px;
  background: linear-gradient(40deg, #519df8, #248afd);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: transform .2s ease-out;
  float: left;
}

.file-input label svg {
  margin-right: 10px;
}

.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  margin-left: 20px;
  color: red;
  cursor: pointer;
}

.move svg {
  cursor: pointer;
  color: #e0e0e0;
}

.move svg:hover {
  color: #0c63e4;
}

</style>
